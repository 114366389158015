.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-header-text{
  font-family:"BerlinSansMain" ;

  
}

.navbar .nav-pills .nav-link{
  color: rgb(123, 2, 2);
  
}
.navbar .nav-pills .nav-link.active{
  background-color: rgb(123, 2, 2);
  color: white;
}
.navbar .nav-link{
  font-family: "BerlinSansMain";
}
.navbar-main h3{
  font-size: 14px !important;
  color: rgb(123, 2, 2);
}
.about-txt{
  text-align: justify;
}
.team-members h3{
  font-family:"BerlinSansMain" ;
}
.contactus, .aboutus h3{
  font-family:"BerlinSansMain" ;
}
@font-face {
  font-family: "Freehand";
  src: local("FreehandLight"), url("./Fonts/Freehand.ttf") format("truetype");
 }

 @font-face {
  font-family: "BerlinSansMain";
  src: local("BerlinSansMainFB"), url("./Fonts/BRLNSDB.TTF");
 }

 @font-face {
  font-family: "MisterEarl";
  src: local("MisterEarlBTRegular"),url("./Fonts/MisterEarlBTRegular.TTF");
 }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 580px) {
  
  .navbar-bg {
    display: none;
  }
  .navbar-main h3{
    font-size: 8px !important;
  }
}